/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { ILocationBarcode } from "../../model";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { Spin } from "antd";
import { RootState } from "../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { FetchLocationBarCode } from "../../../../redux/actions/locations.action";

const LocationBarcode: FC<ILocationBarcode> = ({
  location,
  submit,
  cancel,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const isLocationBarCodeLoading = useSelector(
    (state: RootState) => state.locations.isLocationBarCodeLoading
  );
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const fetchLocationBarCode = () => {
    dispatch(FetchLocationBarCode(location.id ?? ""))
      .then(async (res: any) => {
        const blob = new Blob([res], { type: "image/png" });
        const objectUrl = URL.createObjectURL(blob);
        setImageSrc(objectUrl);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (location) {
      fetchLocationBarCode();
    }
  }, [location]);

  const handleDownload = () => {
    if (!imageSrc) return;
    const link = document.createElement("a");
    link.href = imageSrc;
    link.download = `${location.location_code}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="w-full">
      <div className="w-full drawer-content-height-no-step">
        <div className="barcode-in-drawer">
          <Spin spinning={isLocationBarCodeLoading}>
            {imageSrc ? (
              <img
                src={imageSrc}
                alt={"Location Barcode"}
                className={"h-[154px]"}
              />
            ) : (
              <></>
            )}
          </Spin>
        </div>
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
          <div className={"col-1 sm:block hidden"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Go back"}
              type={"button"}
              onClick={cancel}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !rounded-[6px]"}
              state={ButtonState.PRIMARY}
              text={"Print barcode"}
              type={"button"}
              onClick={handleDownload}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { LocationBarcode };
