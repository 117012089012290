import { Popover, Table } from "antd";
import React, { FC, useState } from "react";
import "../../styles.scss";
import { IAllLocations } from "../../model";
import { ReactComponent as QuicstockMore } from "../../../../assets/svgs/quicstock-more.svg";
import { ReactComponent as QuicstockEye } from "../../../../assets/svgs/quicstock-eye.svg";
import { ReactComponent as QuicstockUploadDoc } from "../../../../assets/svgs/quicstock-upload-doc.svg";
import { ReactComponent as QuicstockTrash } from "../../../../assets/svgs/quicstock-trash.svg";
import { ReactComponent as QuicstockReceiptAdd } from "../../../../assets/svgs/quicstock-receipt-add.svg";
import { ReactComponent as QuicstockPrintBarcode } from "../../../../assets/svgs/quicstock-print-barcode.svg";
import TableActions from "../../../../components/TableActions";
import { itemRender, paginationFooter } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { LocationItemModel } from "../../../../model/location.model";

const ACTION_STYLE =
  "text-sm-regular mb-4 p-2 gap-x-3 flex items-center cursor-pointer";

const AllLocations: FC<IAllLocations> = ({
  deleteBulkLocation,
  updateLocation,
  deleteLocation,
  addProduct,
  printBarcode,
  type,
  paginationData,
  setPaginationData,
  setSelectedItems,
  selectedItems,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.locations.isLocationActionLoading
  );
  const locations = useSelector(
    (state: RootState) => state.locations.locations
  );
  const navigate = useNavigate();
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [open, setOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [id, setId] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [location, setLocation] = useState<LocationItemModel | undefined>(
    undefined
  );

  const handleOpenChange = (newOpen: boolean, record: any) => {
    console.log(record)
    setOpen(newOpen);
    setCurrentRow(record?.key);
    setId(record?.id);
    setLocation({
      name: record?.name,
      location_code: record?.location?.code,
      description: record?.description,
      id: record?.id,
    } as any);
  };

  const handleMore = (
    option: "VIEW" | "UPDATE" | "DELETE" | "ADD" | "PRINT"
  ) => {
    if (option === "DELETE") {
      setDeleteConfirmation(!deleteConfirmation);
    } else {
      if (option === "VIEW") {
        if (type === "OFFLINE")
          navigate(`/dashboard/location-mamagement/sales/${id}`);
        if (type === "ONLINE")
          navigate(`/dashboard/location-mamagement/online-sales/${id}`);
        if (type === "BULK")
          navigate(`/dashboard/location-mamagement/bulk/${id}`);
      }
      if (option === "UPDATE" && updateLocation) {
        updateLocation(location as any);
      }
      if (option === "ADD" && addProduct) addProduct(location as any);
      if (option === "PRINT" && printBarcode) printBarcode(location as any);
      setOpen(false);
    }
  };

  const handleSingleDelete = (e: any) => {
    e.stopPropagation();
    setDeleteConfirmation(false);
    setOpen(false);
    deleteLocation && deleteLocation(id);
  };

  const columns = [
    {
      title: "Location name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No of products",
      dataIndex: "productCount",
      key: "productCount",
    },
    {
      title: "Location code",
      dataIndex: "locationCode",
      key: "locationCode",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: <QuicstockMore />,
      key: "actions",
      render: (record: any) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record);
          }}
        >
          <div className="cursor-pointer">
            <QuicstockMore />
          </div>
        </Popover>
      ),
    },
  ];

  const content = (
    <div className="w-[210px] table-menu">
      <div className={ACTION_STYLE} onClick={() => handleMore("VIEW")}>
        <QuicstockEye />
        View more
      </div>
      <div className={ACTION_STYLE} onClick={() => handleMore("UPDATE")}>
        <QuicstockUploadDoc />
        Update
      </div>
      <div
        className={`${ACTION_STYLE} ${deleteConfirmation ? "delete-confirmation" : "delete-no-confirmation"}`}
        onClick={() => handleMore("DELETE")}
      >
        <div className="first">
          <QuicstockTrash />
        </div>
        Delete
        <div className="last" onClick={handleSingleDelete}>
          <QuicstockTrash />
        </div>
      </div>
      <div className={ACTION_STYLE} onClick={() => handleMore("ADD")}>
        <QuicstockReceiptAdd />
        Add
      </div>
      <div
        className={`${ACTION_STYLE} !mb-0`}
        onClick={() => handleMore("PRINT")}
      >
        <QuicstockPrintBarcode />
        Print barcode
      </div>
    </div>
  );

  const data =
    locations &&
    locations.locations &&
    locations?.locations?.length > 0 &&
    locations?.locations?.map((location, index) => {
      return {
        key: index,
        name: location.name ?? "---",
        productCount: "---",
        locationCode: location?.code ? location?.code : "---",
        description: location?.description ? location?.description : "---",
        id: location?.id,
        location
      };
    });

  const onShowSizeChange = (current: number, pageSize: number) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current: number, pageSize: number) => {
    setPaginationData({
      ...paginationData,
      itemsPerPage: pageSize,
      page: current,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedItems(selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleExport = () => {};
  const handleFilter = () => {};

  return (
    <>
      <TableActions
        label={"Actions you can perform"}
        handleExport={handleExport}
        handleFilter={handleFilter}
        handleDelete={deleteBulkLocation}
        disableDelete={selectedItems.length === 0}
      />
      <div className="w-full bg-white filter-table pb-4 overflow-hidden mt-10">
        <Table
          loading={isLoading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table"}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: locations?.total_count,
            pageSize: defaultPageSize,
            showTotal: (total) =>
              paginationFooter(paginationData, locations?.total_count, total),
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender: (_, type, originalElement) =>
              itemRender(
                _,
                type,
                originalElement,
                locations?.total_count,
                defaultPageSize
              ),
          }}
        />
      </div>
    </>
  );
};

export { AllLocations };
