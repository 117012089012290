/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { AuthHeading } from "../components/AuthHeading";
import { useNavigate } from "react-router-dom";
import AuthStepper from "../../../components/AuthStepper";
import Back from "../../../components/Back";
import "../styles.scss";
import * as yup from "yup";
import Button from "../../../components/Button";
import { ButtonState } from "../../../components/enum";
import { CreatePasswordModel } from "../../../model/auth.model";
import { Form, Input } from "antd";
import { Formik } from "formik";
import FieldName from "../../../components/FieldName";
import PasswordField from "../../../components/Input/PasswordField";

const ACTIVE =
  "px-4 py-2 bg-link-green text-sm-regular rounded-[40px] text-white";
const INACTIVE =
  "px-4 py-2 bg-instock-bg text-sm-regular rounded-[40px] text-gray-300";

const AccountSetPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [values] = useState<CreatePasswordModel>({
    password: "",
    confirmPassword: "",
  });
  const [passwordCriteria, setPasswordCriteria] = useState({
    hasMinLength: false,
    hasSpecialChar: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
  });

  const validatePasswordCriteria = (password: string) => {
    return {
      hasMinLength: password.length > 7,
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumber: /[0-9]/.test(password),
    };
  };

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPasswordCriteria(validatePasswordCriteria(newPassword));
  };

  const createPassword = (values: CreatePasswordModel) => {
    navigate("/");
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="w-full">
      <div className="mb-10 flex items-start justify-end">
        <Back isPrevious={true} />
      </div>
      <div className="text-left gap-y-2 flex flex-col">
        <AuthHeading subHeading={"Create your account"}>
          <p className="text-center mb-6 text-gray-300 text-sm-regular">
            Fil the details below to create a new account with Quicstock and
            let’s get the party started
          </p>
        </AuthHeading>
        <AuthStepper active={2} size={3}></AuthStepper>
        <section className="mt-6">
          <p className="text-sm-headline font-medium text-black mb-4">
            For logging in
          </p>

          <Formik
            initialValues={values}
            onSubmit={createPassword}
            enableReinitialize={true}
            validationSchema={validator}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <Form onFinish={handleSubmit} form={form}>
                <div className={"mb-4"}>
                  <FieldName name={"Password"} required />
                  <PasswordField
                    showPassword={showPassword}
                    handleShowPassword={handleShowPassword}
                  >
                    <Form.Item
                      name={"password"}
                      help={errors.password ? errors.password : ""}
                      validateStatus={errors.password ? "error" : "success"}
                    >
                      <Input
                        type={showPassword ? "text" : "password"}
                        className={"form-field_input_2"}
                        value={values?.password || ""}
                        onChange={(e) => {
                          handleChange(e);
                          handlePasswordChange(e);
                        }}
                        onBlur={handleBlur}
                        placeholder="Enter password"
                      />
                    </Form.Item>
                  </PasswordField>
                </div>
                <div className={"mb-10"}>
                  <FieldName name={"Confirm password"} required />
                  <PasswordField
                    showPassword={showConfirmPassword}
                    handleShowPassword={handleShowConfirmPassword}
                  >
                    <Form.Item
                      name={"confirmPassword"}
                      help={
                        errors.confirmPassword ? errors.confirmPassword : ""
                      }
                      validateStatus={
                        errors.confirmPassword ? "error" : "success"
                      }
                    >
                      <Input
                        type={showConfirmPassword ? "text" : "password"}
                        className={"form-field_input_2"}
                        value={values?.confirmPassword || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter password again"
                      />
                    </Form.Item>
                  </PasswordField>
                </div>
                <div className={"mb-10 flex flex-wrap gap-5"}>
                  <div
                    className={
                      passwordCriteria.hasMinLength ? ACTIVE : INACTIVE
                    }
                  >
                    {"> 7 Characters"}
                  </div>
                  <div
                    className={
                      passwordCriteria.hasSpecialChar ? ACTIVE : INACTIVE
                    }
                  >
                    {"Special character"}
                  </div>
                  <div
                    className={
                      passwordCriteria.hasUppercase ? ACTIVE : INACTIVE
                    }
                  >
                    {"Uppercase"}
                  </div>
                  <div
                    className={
                      passwordCriteria.hasLowercase ? ACTIVE : INACTIVE
                    }
                  >
                    {"Lowercase"}
                  </div>
                  <div
                    className={passwordCriteria.hasNumber ? ACTIVE : INACTIVE}
                  >
                    {"Number"}
                  </div>
                </div>
                <div
                  className={
                    "text-sm-regular flex items-center gap-x-2 mb-10 flex-wrap text-left leading-7"
                  }
                >
                  <p className="text-gray-300">
                    By creating an account, you ‘re agreeing with our
                  </p>
                  <span className="font-medium text-black">Privacy Policy</span>
                  <p className="text-gray-300">and</p>
                  <span className="font-medium text-black">
                    Inventory Policy
                  </span>
                </div>
                <div className={"flex justify-center"}>
                  <Button
                    css={"w-full"}
                    state={ButtonState.SECONDARY}
                    text={"Get into Quicstock"}
                    type={"submit"}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </div>
    </div>
  );
};

const validator = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

export default AccountSetPassword;
