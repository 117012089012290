import React, { FC, useEffect } from "react";
import "../styles.scss";
import { Outlet, useNavigate } from "react-router-dom";
import QuicstockLogoWhite from "../../assets/imgs/quicstock-logo-white.png";
import { Carousel } from "antd";

const AuthLayout: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("quicstock_app_token");
    const refreshToken = localStorage.getItem("quicstock_app_refresh_token");
    if (token && refreshToken) {
      navigate("/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-white auth-main lg:pl-[50px] lg:pt-[50px] lg:pb-[50px]">
      <div className="lg:flex">
        <div className="hidden lg:flex lg:w-1/2 auth-slider">
          <Carousel autoplay>
            <div className={"slider-pain one"}>
              <img
                className={"lg:h-6 h-4"}
                src={QuicstockLogoWhite}
                alt={"Logo"}
              />

              <p className="mt-20 text-white font-semibold xl:text-headline text-md-headline">
                Have all your products in one place
              </p>
            </div>
            <div className={"slider-pain two"}>
              <img
                className={"lg:h-6 h-4"}
                src={QuicstockLogoWhite}
                alt={"Logo"}
              />

              <p className="mt-20 text-white font-semibold xl:text-headline text-md-headline">
                Have all your products in one place
              </p>
            </div>
            <div className={"slider-pain three"}>
              <img
                className={"lg:h-6 h-4"}
                src={QuicstockLogoWhite}
                alt={"Logo"}
              />

              <p className="mt-20 text-white font-semibold xl:text-headline text-md-headline">
                Have all your products in one place
              </p>
            </div>
          </Carousel>
        </div>
        <div className="lg:w-1/2 w-full relative overflow-y-auto">
          <div className="flex-1 auth-container relative px-4 sm:px-32 md:px-48 lg:px-16 xl:px-20 lg:max-w-2xl w-full">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
