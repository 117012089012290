/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { AuthHeading } from "../components/AuthHeading";
import AuthStepper from "../../../components/AuthStepper";
import { useNavigate } from "react-router-dom";
import AccountDetails from "./components/AccountDetails";
import { AccountInformationModel } from "../../../model/auth.model";
import AccountOtpValidation from "./components/AccountOtpValidation";

const AccountInformation = () => {
  const navigate = useNavigate();
  const [section, setSection] = useState<number>(0);

  const handleSetAccountDetails = (values: AccountInformationModel) => {
    localStorage.setItem("quicstock_app_account_setup", JSON.stringify(values));
    setSection(1);
  };

  const handleOtpValidation = (otp: string) => {
    const acc = localStorage.getItem("quicstock_app_account_setup");
    if (acc) {
      const account: AccountInformationModel = JSON.parse(acc);
      account.otp = otp;
      localStorage.setItem(
        "quicstock_app_account_setup",
        JSON.stringify(account)
      );
      navigate("/auth/sign-up/pick-a-plan");
    }
  };

  return (
    <div className="w-full">
      <div className="text-left gap-y-2 flex flex-col">
        <AuthHeading subHeading={"Create your account"}>
          <p className="text-center mb-6 text-gray-300 text-sm-regular">
            Fil the details below to create a new account with Quicstock and
            let’s get the party started
          </p>
        </AuthHeading>
        <AuthStepper active={0} size={3}></AuthStepper>
        {section === 0 ? (
          <AccountDetails submit={handleSetAccountDetails} />
        ) : (
          <AccountOtpValidation
            submit={handleOtpValidation}
            handleBack={() => setSection(0)}
          />
        )}
      </div>
    </div>
  );
};

export default AccountInformation;
