import React, { useEffect, useState } from "react";
import "../styles.scss";
import {
  ConfirmProduct,
  PurchaseBreakdown,
  PurchaseInfo,
  PurchaseItem,
} from "./components";
import { RightOutlined } from "@ant-design/icons";
import { Collapse, CollapseProps, Progress } from "antd";
import QuicstockNoImage from "../../../assets/imgs/quicstock-no-image.png";
import { useParams } from "react-router-dom";
import { flCapital, goBack } from "../../../utils/constants";
import Breadcrumb from "../../../components/Breadcrumb";
import AppDrawer from "../../../components/AppDrawer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { RootState } from "../../../redux/reducers";
import { ConfirmPo, FetchPo } from "../../../redux/actions/pos.action";
import Button from "../../../components/Button";
import { ButtonState } from "../../../components/enum";
import { IConfirmProductFormField } from "../model";
import {
  PoComfirmationModel,
  PoComfirmationProductModel,
  PoResponseModel,
} from "../../../model/po.model";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";

interface IPurchaseQuantity {
  totalExpected: number;
  totalReceived: number;
  percentage: number;
}

const ConfirmPurchaseOrder = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const po = useSelector((state: RootState) => state.pos.po);
  const [id, setId] = useState<string>("");
  const [openConfirmProductDrawer, setConfirmProductDrawer] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [values, setValues] = useState<IConfirmProductFormField>({
    products: [],
    additionalInformation: "",
  });
  const [closePoFromForm, setClosePoFromForm] = useState<boolean>(false);

  useEffect(() => {
    if (params?.id) {
      setId(params?.id);
      dispatch(FetchPo(params?.id));
    } else {
      goBack();
    }
  }, [dispatch, params]);

  const toggleConfirmProductDrawer = () => {
    setConfirmProductDrawer(!openConfirmProductDrawer);
  };

  const confirmProduct = () => {
    setActiveStep(0);
    toggleConfirmProductDrawer();
  };

  const handleSubmit = (formValues: IConfirmProductFormField, closePoFromForm: boolean) => {
    setValues(formValues);
    setClosePoFromForm(closePoFromForm);
    setActiveStep(1);
  };

  const handleComplete = (closePo: boolean) => {
    const products: PoComfirmationProductModel[] = [];
    for (let i = 0; i < values?.products?.length; i++) {
      products.push({
        product_id: values?.products[i]?.product?.product_id,
        location: [],
      });
      for (let j = 0; j < values?.products[i]?.fields?.length; j++) {
        products[i].location.push({
          quantity: values?.products[i]?.product?.uom?.factor
            ? parseInt(values?.products[i]?.fields[j]?.quantity) *
              values?.products[i]?.product?.uom?.factor
            : parseInt(values?.products[i]?.fields[j]?.quantity),
          location_id: values?.products[i]?.fields[j]?.location_id,
        });
      }
    }
    const payload: PoComfirmationModel = {
      po_id: id,
      force_close: closePo ?? false,
      po_number: po?.po_number ?? "",
      products,
    };
    confirmPo(payload);
  };

  const calculateSubTotal = (): number => {
    let total: number = 0;
    for (let i = 0; i < (po?.products?.length ?? 0); i++) {
      total =
        total +
        (po?.products?.[i].unit_price ?? 0) *
          (po?.products?.[i].quantity_expected ?? 0);
    }
    return total;
  };

  const calculatePercentageCompletion = (): IPurchaseQuantity => {
    let totalExpected: number = 0;
    let totalReceived: number = 0;
    for (let i = 0; i < (po?.products?.length ?? 0); i++) {
      totalExpected =
        totalExpected + (po?.products?.[i]?.quantity_expected ?? 0);
      totalReceived =
        totalReceived + (po?.products?.[i]?.quantity_received ?? 0);
    }
    return {
      totalExpected,
      totalReceived,
      percentage: (totalReceived / totalExpected) * 100,
    };
  };

  const confirmPo = (payload: PoComfirmationModel) => {
    dispatch(ConfirmPo(payload))
      .then(async (res: PoResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="New supplier added"
            message={res?.message}
          />,
          true
        );
        toggleConfirmProductDrawer();
        setActiveStep(0);
        dispatch(FetchPo(id));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const getItems: CollapseProps["items"] = [
    {
      key: "1",
      label: "Products",
      children: (
        <div className="w-full flex flex-col gap-y-4 mt-4">
          <div className="mb-2">
            {po?.products?.map((p, index) => (
              <PurchaseItem
                key={`${p?.product_id} ${index}`}
                image={p?.image_url || QuicstockNoImage}
                name={p?.name ?? "No name"}
                unit_price={p?.unit_price ?? 0}
                total={(p?.unit_price ?? 0) * (p?.quantity_expected ?? 0)}
                quantity={p?.quantity_expected ?? 0}
                locations={p?.locations}
                uom={p?.uom}
              />
            ))}
          </div>
        </div>
      ),
    },
  ];

  const getBreakdown: CollapseProps["items"] = [
    {
      key: "2",
      label: "Breakdown",
      children: (
        <PurchaseBreakdown
          sub_total={calculateSubTotal()}
          tax={0}
          shipping={0}
          total={calculateSubTotal()}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full">
      <div className="flex w-full gap-x-4">
        <Breadcrumb
          names={["Purchases", `Confirm ${po?.po_number}`]}
          isPrevious={true}
          showBackButton={true}
        />
      </div>
      <div className="default-page-height w-full xl:pt-20 pt-10 flex justify-center overflow-y-auto">
        <div className="xl:w-8/12 w-full">
          <PurchaseInfo
            name={po?.supplier_data?.name ?? ""}
            address={po?.supplier_data?.address ?? ""}
            sent={true}
          />
          <div className="mt-8 w-full gap-x-6 grid sm:grid-cols-2 grid-cols-1 px-6">
            <button className="col font-medium border rounded-[10px] p-3 border-black sm:text-sm-headline text-labels">
              Download
            </button>
            <button className="col font-medium rounded-[10px] p-3 bg-dark-300 sm:mt-0 mt-4 sm:text-sm-headline text-labels">
              Send to supplier
            </button>
          </div>
          <div className="px-6 py-4 bg-dark-400 mt-8">
            <Collapse
              className="custom-collpse-md"
              expandIcon={({ isActive }) => (
                <RightOutlined rotate={isActive ? 90 : 0} />
              )}
              defaultActiveKey={["1"]}
              expandIconPosition="right"
              items={getItems}
            />
          </div>
          <div className="px-6 py-4 bg-dark-400 mt-8">
            <Collapse
              className="custom-collpse-md"
              expandIcon={({ isActive }) => (
                <RightOutlined rotate={isActive ? 90 : 0} />
              )}
              defaultActiveKey={["2"]}
              expandIconPosition="right"
              items={getBreakdown}
            />
          </div>
          <div className="mt-6 mb-4 border-[0.5px] border-[#EFEFEF]"></div>

          <div className="flex gap-x-10 items-center">
            <p className="text-labels">Status of purchase:</p>
            <div className={`status ${po?.status?.toLowerCase()}`}>
              {flCapital(po?.status ?? "")}
            </div>
          </div>
          <div className="flex items-center gap-x-4">
            <Progress
              percent={calculatePercentageCompletion().percentage}
              size={["100%", 6]}
              strokeColor={"#09821A"}
              showInfo={false}
            />
            <p className="text-labels">
              {calculatePercentageCompletion().totalReceived}/
              {calculatePercentageCompletion().totalExpected}
            </p>
          </div>

          {po?.status !== "Completed" && (
            <div className="flex flex-col justify-center items-center mt-4">
              <p className="text-labels mb-2">
                Click on the button below if you have received (all) your
                order(s)
              </p>
              <div className="sm:w-1/2 w-full sm:mt-0 mt-4">
                <Button
                  css={"w-full !px-0 !rounded-[6px]"}
                  state={ButtonState.SECONDARY}
                  text={"Continue confirming order"}
                  type={"button"}
                  onClick={confirmProduct}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Confirm product */}
      <AppDrawer
        open={openConfirmProductDrawer}
        toggle={toggleConfirmProductDrawer}
        title={`Confirm ${po?.po_number}`}
        subtitle={"View the details of this location below"}
      >
        <ConfirmProduct
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={(closePo) => handleComplete(closePo)}
          cancel={toggleConfirmProductDrawer}
          activeStep={activeStep}
          formValues={values}
          closePoFromForm={closePoFromForm}
        />
      </AppDrawer>
      {/* Confirm product */}
    </div>
  );
};

export default ConfirmPurchaseOrder;
