import { ThunkAction } from "redux-thunk";
import { CreatePasswordModel, LoginModel } from "../../model/auth.model";
import { login, setPassword, verifyLink } from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";

export const Types = {
  AUTH_START: "AUTH_START",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_END: "AUTH_END",

  VERIFY_LINK_START: "VERIFY_LINK_START",
  VERIFY_LINK_SUCCESS: "VERIFY_LINK_SUCCESS",
  VERIFY_LINK_END: "VERIFY_LINK_END",

  SET_PASSWORD_START: "SET_PASSWORD_START",
  SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
  SET_PASSWORD_END: "SET_PASSWORD_END",
};

export const LoginUser = (
  req: LoginModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.AUTH_START });
    return new Promise((resolve, reject) => {
      login(req)
        .then(({ data }) => {
          dispatch({ type: Types.AUTH_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.AUTH_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const VerifyLink = (
  token: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.VERIFY_LINK_START });
    return new Promise((resolve, reject) => {
      verifyLink(token)
        .then(({ data }) => {
          dispatch({ type: Types.VERIFY_LINK_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.VERIFY_LINK_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const SetPassword = (
  req: CreatePasswordModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.SET_PASSWORD_START });
    return new Promise((resolve, reject) => {
      setPassword(req)
        .then(({ data }) => {
          dispatch({ type: Types.SET_PASSWORD_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.SET_PASSWORD_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};
