import React, { FC, useEffect, useState } from "react";
import QuicstockLogoWhite from "../../assets/imgs/quicstock-logo-white.png";
import Quicstock from "../../assets/imgs/quicstock.png";
import QuicstockUser from "../../assets/imgs/quicstock-user.png";
import "../styles.scss";
import { ReactComponent as QuicstockDot } from "../../assets/svgs/quicstock-dot.svg";
import { ReactComponent as QuicstockInactiveDot } from "../../assets/svgs/quicstock-inactive-dot.svg";
import { NavLink, useLocation, Outlet, useNavigate } from "react-router-dom";
import { ReactComponent as QuicstockToggle } from "../../assets/svgs/quicstock-toggle.svg";
import { ReactComponent as QuicstockChevronUp } from "../../assets/svgs/quicstock-chevron-up.svg";
import { ReactComponent as QuicstockLogout } from "../../assets/svgs/quicstock-logout.svg";
import { Collapse } from "antd";
import { routes } from "../../utils/misc/routes";
import { capitalize } from "lodash";
const { Panel } = Collapse;

const ACTIVE_NAV_CLASS = "w-full text-white cursor-pointer items-center";
const INACTIVE_NAV_CLASS =
  "w-full text-gray-white hover:text-white cursor-pointer items-center";
const ACTIVE_ANCHOR_CLASS =
  "flex items-center focus:outline-none menu is-active";
const INACTIVE_ANCHOR_CLASS = "flex items-center focus:outline-none menu";
const ACTIVE_CHILD_ROUTE =
  "rounded-xl text-white bg-gray-200 text-left px-3 py-3 w-full text-labels my-2";
const INACTIVE_CHILD_ROUTE =
  "rounded-xl text-gray-300 hover:text-white text-left px-3 py-3 w-full text-labels my-2";
const MOBILE_ACTIVE_CHILD_ROUTE =
  "rounded-xl text-white bg-gray-200 text-left px-3 py-3 w-full text-sm-regular my-2 flex items-center justify-center font-semibold";
const MOBILE_INACTIVE_CHILD_ROUTE =
  "rounded-xl text-gray-300 hover:text-white text-left px-3 py-3 w-full text-labels my-2 flex items-center justify-center font-semibold";

const MainLayout: FC = () => {
  const navigate = useNavigate();
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [user, setUser] = useState<{ name?: string; role?: string }>({});

  const useFindPath = () => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
      setCurrentPath(location.pathname);
    }, [location]);
    return currentPath;
  };

  const path = useFindPath();

  const toggleSidebar = () => {
    if (isSideBarOpen) {
      setIsSideBarOpen(false);
    } else {
      setIsSideBarOpen(true);
    }
  };

  useEffect(() => {
    const checkScreenWidth = () => {
      const width = window.innerWidth;
      setScreenWidth(width);
      setIsSideBarOpen(width > 768);
    };
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const handleNavState = (key: string | string[]) => {
    if (key.length) {
      setIsSideBarOpen(true);
    } else {
      if (screenWidth <= 768) {
        setIsSideBarOpen(false);
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("quicstock_app_token");
    const refreshToken = localStorage.getItem("quicstock_app_refresh_token");
    const _user = JSON.parse(localStorage.getItem("quicstock_app_user") ?? "");
    if (!token || !refreshToken) {
      navigate("/");
    } else {
      setUser(_user);
    }
  }, [navigate]);

  const logout = () => {
    localStorage.removeItem("quicstock_app_token");
    localStorage.removeItem("quicstock_app_refresh_token");
    localStorage.removeItem("quicstock_app_user");
    navigate("/");
  };

  return (
    <div className="overflow-hidden relative">
      <div className="w-full main-nav">
        <div className={"h-full"}>
          <div className={`flex flex-no-wrap h-full bg-gray-400`}>
            <div
              style={{ minHeight: "716px" }}
              className={`${isSideBarOpen ? "side-nav" : "mobile-side-nav"} absolute z-10 sm:relative md:h-full flex-col justify-between`}
            >
              <div className="px-4 pt-16">
                <div className="logo-container">
                  <img
                    src={QuicstockLogoWhite}
                    alt={"QuicstockLogoWhite"}
                    className={"ml-6 h-[24px] quickstock-logo-lg"}
                  />
                  <img
                    src={Quicstock}
                    alt={"Quicstock"}
                    className={"h-[24px] quickstock-logo-sm"}
                  />
                </div>
                <div className="menu-items-container custom-scrollbar">
                  <ul
                    className={`mt-12 ${isSideBarOpen ? "accordion" : "mobile-accordion"}`}
                  >
                    {routes?.map((route) => (
                      <li
                        className={
                          path.includes(route.path)
                            ? ACTIVE_NAV_CLASS
                            : INACTIVE_NAV_CLASS
                        }
                        key={route?.id + "full screen"}
                      >
                        {route?.children ? (
                          <Collapse
                            defaultActiveKey={
                              path?.includes(route.path) ? [route.path] : []
                            }
                            accordion
                            className={`${isSideBarOpen ? "nav-route" : "mobile-nav-route"}`}
                            bordered={false}
                            expandIconPosition={"end"}
                            onChange={handleNavState}
                            expandIcon={({ isActive }) =>
                              isActive && isSideBarOpen ? (
                                <QuicstockChevronUp />
                              ) : (
                                <></>
                              )
                            }
                          >
                            <Panel
                              key={
                                path?.includes(route.path) ? route.path : "0"
                              }
                              header={
                                <div
                                  className={
                                    path?.includes(route.path)
                                      ? ACTIVE_ANCHOR_CLASS
                                      : INACTIVE_ANCHOR_CLASS
                                  }
                                >
                                  <div className={"route-icon"}>
                                    {route.icon}
                                  </div>
                                  <span
                                    className={"text-sm-regular ml-3 nav-label"}
                                  >
                                    {route.name}
                                  </span>
                                </div>
                              }
                            >
                              {route?.children?.map((im) => (
                                <NavLink
                                  to={im.path}
                                  key={im?.name}
                                  onClick={() => handleNavState([])}
                                >
                                  <div className="w-full flex items-center gap-x-2 quic-dot-wrapper pl-10">
                                    <div className="quic-dot">
                                      {path.includes(im.path) ? (
                                        <QuicstockDot />
                                      ) : (
                                        <QuicstockInactiveDot />
                                      )}
                                    </div>
                                    <p
                                      className={
                                        path.includes(im.path)
                                          ? isSideBarOpen
                                            ? ACTIVE_CHILD_ROUTE
                                            : MOBILE_ACTIVE_CHILD_ROUTE
                                          : isSideBarOpen
                                            ? INACTIVE_CHILD_ROUTE
                                            : MOBILE_INACTIVE_CHILD_ROUTE
                                      }
                                    >
                                      {isSideBarOpen ? im?.name : im?.name[0]}
                                    </p>
                                  </div>
                                </NavLink>
                              ))}
                            </Panel>
                          </Collapse>
                        ) : (
                          <NavLink
                            to={route.path}
                            className={
                              path.includes(route.path)
                                ? ACTIVE_ANCHOR_CLASS
                                : INACTIVE_ANCHOR_CLASS
                            }
                            onClick={() => handleNavState([])}
                          >
                            <div className={"ml-3 route-icon"}>
                              {route.icon}
                            </div>
                            <span className={"text-sm-regular ml-3 nav-label"}>
                              {route.name}
                            </span>
                          </NavLink>
                        )}
                      </li>
                    ))}
                  </ul>

                  <div
                    className={`pt-12 text-white ${isSideBarOpen ? "quicstock-user-wrapper" : "mobile-quicstock-user-wrapper"}`}
                  >
                    <button
                      onClick={logout}
                      className="p-3 bg-gray-400 rounded-[10px] border-[0.25px] border-gray-100 flex items-center justify-between w-full"
                    >
                      <div className="flex items-center gap-x-2 bg-primary-400 rounded-lg text-labels font-medium quicstock-user">
                        <img
                          src={QuicstockUser}
                          alt={"QuicstockUser"}
                          className={"h-[42px]"}
                        />
                        <div className="text-left">
                          <p className="pl-2 text-sm-regular">{user.name}</p>
                          <p className="pl-2 text-labels text-gray-300">
                            {capitalize(user.role)}
                          </p>
                        </div>
                      </div>
                      <QuicstockLogout />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`py-6 ${isSideBarOpen ? "main-content" : "mobile-main-content"} bg-white mt-6`}
            >
              <button onClick={toggleSidebar} className="nav-toggle">
                <QuicstockToggle />
              </button>
              <div className={"w-full sm:px-8 px-4"}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
