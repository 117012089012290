/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { Form, Input } from "antd";
import { Field, Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { AccountInformationModel } from "../../../../model/auth.model";
import FieldName from "../../../../components/FieldName";
import { IAccountDetails } from "../../model";

const AccountDetails: FC<IAccountDetails> = ({ submit }) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState<AccountInformationModel>({
    storeName: "",
    storeEmail: "",
    storePrimaryActivity: "",
    storeLocation: "",
    fullName: "",
    position: "",
    otp: "",
  });

  const setAccountInformation = (values: AccountInformationModel) => {
    submit(values);
  };

  useEffect(() => {
    const acc = localStorage.getItem("quicstock_app_account_setup");
    if (acc) {
      const account: AccountInformationModel = JSON.parse(acc);
      setValues(account);
    }
  }, []);

  return (
    <div className="mt-6">
      <Formik
        initialValues={values}
        onSubmit={setAccountInformation}
        enableReinitialize={true}
        validationSchema={validator}
      >
        {({ values, errors, touched, handleSubmit }) => (
          <Form onFinish={handleSubmit} form={form}>
            <section className="mb-10">
              <p className="text-sm-headline font-medium text-black mb-4">
                Store’s information
              </p>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FieldName name={"Your store’s name"} required />
                  <Form.Item
                    name={"storeName"}
                    help={
                      touched.storeName && errors.storeName
                        ? errors.storeName
                        : ""
                    }
                    validateStatus={
                      touched.storeName && errors.storeName
                        ? "error"
                        : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          value={values?.storeName}
                          onChange={(e) => {
                            setFieldValue("storeName", e.target.value);
                          }}
                          onBlur={(e) => {
                            setFieldValue("storeName", e.target.value);
                          }}
                          placeholder="Enter store’s name"
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FieldName name={"Store’s email address"} required />
                  <Form.Item
                    name={"storeEmail"}
                    help={
                      touched.storeEmail && errors.storeEmail
                        ? errors.storeEmail
                        : ""
                    }
                    validateStatus={
                      touched.storeEmail && errors.storeEmail
                        ? "error"
                        : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <Input
                          type={"email"}
                          className={"form-field_input_2"}
                          value={values?.storeEmail || ""}
                          onChange={(e) => {
                            setFieldValue("storeEmail", e.target.value);
                          }}
                          onBlur={(e) => {
                            setFieldValue("storeEmail", e.target.value);
                          }}
                          placeholder="Enter your email address"
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FieldName name={"Store primary activity"} required />
                  <Form.Item
                    name={"storePrimaryActivity"}
                    help={
                      touched.storePrimaryActivity &&
                      errors.storePrimaryActivity
                        ? errors.storePrimaryActivity
                        : ""
                    }
                    validateStatus={
                      touched.storePrimaryActivity &&
                      errors.storePrimaryActivity
                        ? "error"
                        : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          value={values?.storePrimaryActivity || ""}
                          onChange={(e) => {
                            setFieldValue(
                              "storePrimaryActivity",
                              e.target.value
                            );
                          }}
                          onBlur={(e) => {
                            setFieldValue(
                              "storePrimaryActivity",
                              e.target.value
                            );
                          }}
                          placeholder="Select your store primary activity"
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FieldName name={"Store’s location"} required />
                  <Form.Item
                    name={"storeLocation"}
                    help={
                      touched.storeLocation && errors.storeLocation
                        ? errors.storeLocation
                        : ""
                    }
                    validateStatus={
                      touched.storeLocation && errors.storeLocation
                        ? "error"
                        : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          value={values?.storeLocation || ""}
                          onChange={(e) => {
                            setFieldValue("storeLocation", e.target.value);
                          }}
                          onBlur={(e) => {
                            setFieldValue("storeLocation", e.target.value);
                          }}
                          placeholder="Enter store’s location"
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
            </section>
            <section>
              <p className="text-sm-headline font-medium text-black mb-4">
                Vendor’s information
              </p>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FieldName name={"Your full name"} required />
                  <Form.Item
                    name={"fullName"}
                    help={
                      touched.fullName && errors.fullName ? errors.fullName : ""
                    }
                    validateStatus={
                      touched.fullName && errors.fullName ? "error" : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          value={values?.fullName || ""}
                          onChange={(e) => {
                            setFieldValue("fullName", e.target.value);
                          }}
                          onBlur={(e) => {
                            setFieldValue("fullName", e.target.value);
                          }}
                          placeholder="Enter full name (surname first)"
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-6"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FieldName name={"Your position in company"} required />
                  <Form.Item
                    name={"position"}
                    help={
                      touched.position && errors.position ? errors.position : ""
                    }
                    validateStatus={
                      touched.position && errors.position ? "error" : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          value={values?.position || ""}
                          onChange={(e) => {
                            setFieldValue("position", e.target.value);
                          }}
                          onBlur={(e) => {
                            setFieldValue("position", e.target.value);
                          }}
                          placeholder="Enter position"
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
            </section>
            <div className={"flex justify-center"}>
              <Button
                css={"w-full"}
                state={ButtonState.SECONDARY}
                text={"Proceed"}
                type={"submit"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const validator = yup.object().shape({
  storeName: yup
    .string()
    .min(2, "Store name is too short")
    .required("Store name is required"),
  storeEmail: yup
    .string()
    .email("Invalid email")
    .required("Store email is required"),
  storePrimaryActivity: yup
    .string()
    .min(2, "Store primary is too short")
    .required("Store primary is required"),
  storeLocation: yup
    .string()
    .min(2, "Store location is too short")
    .required("Store location is required"),
  fullName: yup
    .string()
    .min(2, "Full name is too short")
    .required("Full name is required"),
  position: yup
    .string()
    .min(2, "Position is too short")
    .required("Position is required"),
});

export default AccountDetails;
