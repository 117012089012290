/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState } from "react";
import { Form } from "antd";
import { Formik } from "formik";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { AccountInformationModel } from "../../../../model/auth.model";
import { IAccountOtpValidation } from "../../model";
import OtpInput from "react18-input-otp";
import { AuthHeading } from "../../components";
import { ReactComponent as QuicstockModalClose } from "../../../../assets/svgs/quicstock-modal-close.svg";

const AccountOtpValidation: FC<IAccountOtpValidation> = ({
  submit,
  handleBack,
}) => {
  const [form] = Form.useForm();
  const [otp, setOtp] = useState<string>("");

  const handleOtp = (enteredOtp: string) => {
    setOtp(enteredOtp);
  };

  const [values] = useState<AccountInformationModel>({
    storeName: "",
    storeEmail: "",
    storePrimaryActivity: "",
    storeLocation: "",
    fullName: "",
    position: "",
  });

  const setAccountInformation = (_: any) => {
    submit(otp);
  };

  return (
    <div className="mt-6">
      <div className="mt-2">
        <button onClick={handleBack}>
          <QuicstockModalClose />
        </button>
      </div>

      <Formik
        initialValues={values}
        onSubmit={setAccountInformation}
        enableReinitialize={true}
      >
        {({ handleSubmit }) => (
          <Form onFinish={handleSubmit} form={form}>
            <section className="mb-6">
              <div className="mt-6">
                <AuthHeading subHeading={"OTP"}>
                  <p className="text-center mb-8 text-gray-300 text-sm-regular">
                    We just sent you an OTP, kindly input it here
                  </p>
                </AuthHeading>
              </div>
              <div className="otp-container">
                <OtpInput
                  className={"otp-input"}
                  value={otp}
                  onChange={handleOtp}
                  numInputs={4}
                  placeholder="-"
                />
              </div>
            </section>
            <div className={"flex items-center gap-x-2 justify-center mb-10"}>
              <p className="text-sm-regular font-medium text-gray-300">
                Didnt get a pin?
              </p>
              <span className="text-sm-regular font-semibold text-primary-500">
                Resent pin: 54s
              </span>
            </div>

            <div className={"flex justify-center"}>
              <Button
                css={"w-full"}
                state={ButtonState.SECONDARY}
                text={"Verify my pin"}
                type={"submit"}
                disabled={otp.length < 4 ? true : false}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccountOtpValidation;
