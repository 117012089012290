import React, { useEffect, useState } from "react";
import "../styles.scss";
import Back from "../../../components/Back";
import QuicstockNoImage from "../../../assets/imgs/quicstock-no-image.png";
import {
  AddCustomProperties,
  EditProduct,
  EditSellingDetails,
  ProductDetailsItem,
  ProductLocation,
  ProductOrder,
  ProductPurchaseOrder,
} from "./components";
import AppDrawer from "../../../components/AppDrawer";
import { ManageCustomProperties } from "./components/ManageCustomProperties";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import { useParams } from "react-router-dom";
import { formatCurrency, goBack, NAIRA } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  FetchProduct,
  FetchUnits,
  SetRetailPrice,
  UpdateProduct,
} from "../../../redux/actions/products.action";
import { RootState } from "../../../redux/reducers";
import {
  EditProductModel,
  ProductResponseModel,
  SellingRetailPriceModel,
  SellingRetailPriceRequestModel,
} from "../../../model/product.model";

const ProductDetails = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const product = useSelector((state: RootState) => state.products.product);
  const [openEditSellingDetails, setOpenEditSellingDetails] = useState(false);
  const [openManageCustomProperties, setOpenManageCustomProperties] =
    useState(false);
  const [openAddCustomProperties, setOpenAddCustomProperties] = useState(false);
  const [openEditProductDetailsDrawer, setOpenEditProductDetailsDrawer] =
    useState(false);
  const [id, setId] = useState<string>("");
  const [activeStep, setActiveStep] = useState<number>(0);
  const [productDetails, setProductDetails] = useState<EditProductModel>({
    name: "",
    upc: "",
    brand: "",
    category: "",
    weight: "",
    color: "",
    description: "",
    uom: [],
    uoms: [],
  });

  const toggleEditSellingDetails = () => {
    setOpenEditSellingDetails(!openEditSellingDetails);
  };

  const toggleManageCustomProperties = () => {
    setOpenManageCustomProperties(!openManageCustomProperties);
  };

  const toggleAddCustomProperties = () => {
    setOpenAddCustomProperties(!openAddCustomProperties);
  };

  const toggleEditProductDetailsDrawer = () => {
    setOpenEditProductDetailsDrawer(!openEditProductDetailsDrawer);
  };

  const handleSaveSellingDetails = (values: SellingRetailPriceModel) => {
    setRetailPrice(values);
  };

  const handleAddNewProperty = () => {
    toggleManageCustomProperties();
    toggleAddCustomProperties();
  };

  const handleGoBackToManageCustomProperties = () => {
    toggleAddCustomProperties();
    toggleManageCustomProperties();
  };

  const handleSubmit = (values: any) => {
    setProductDetails(values);
    setActiveStep(1);
  };

  const handleComplete = () => {
    updateProduct();
  };

  const handleSaveProperties = () => {
    openNotification(
      <NotificationMessage
        type="success"
        title="Custom property saved"
        message="You’ve successfully saved a new custom property"
      />,
      true
    );
    toggleManageCustomProperties();
  };

  const handleSaveCustomProperties = () => {
    openNotification(
      <NotificationMessage
        type="success"
        title="Custom property added"
        message="You’ve successfully added a new custom property"
      />,
      true
    );
    toggleAddCustomProperties();
    toggleManageCustomProperties();
  };

  const setRetailPrice = (values: SellingRetailPriceModel) => {
    const payload: SellingRetailPriceRequestModel = {
      new_price: parseFloat(values?.sellingPrice.split(",").join("")),
    };
    dispatch(SetRetailPrice(id, payload))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Changes made"
            message={res?.message}
          />,
          true
        );
        toggleEditSellingDetails();
        dispatch(FetchProduct(id));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const updateProduct = () => {
    const formData = new FormData();
    formData.append("name", productDetails?.name ?? "");
    formData.append("upc", productDetails?.upc ?? "");
    formData.append("brand", productDetails?.brand ?? "");
    formData.append("category", productDetails?.category ?? "");
    formData.append("weight", productDetails?.weight ?? "");
    formData.append("color", productDetails?.color ?? "");
    formData.append("description", productDetails?.description ?? "");
    formData.append("uom", productDetails?.uom.join(",") ?? "");
    if (!productDetails?.isImageDeleted && productDetails?.imageFile) {
      formData.append("image", productDetails?.imageFile);
    } else if (productDetails.isImageDeleted) {
      formData.append("image", "");
    }
    dispatch(UpdateProduct(formData, id))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Product updated"
            message={res?.message}
          />,
          true
        );
        toggleEditProductDetailsDrawer();
        setActiveStep(0);
        dispatch(FetchProduct(id));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    if (params?.id) {
      setId(params?.id);
      dispatch(FetchProduct(params?.id));
    } else {
      goBack();
    }
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(FetchUnits());
  }, [dispatch]);

  return (
    <div className="w-full h-full">
      <div className="flex w-full justify-between">
        <Back isPrevious={true} />
        <div className="flex gap-x-3 items-center">
          <button
            onClick={toggleEditProductDetailsDrawer}
            className="bg-dark-100 text-sm-regular font-medium rounded-[10px] px-4 py-2 hover:bg-gray-600 hover:text-white transition ease-in-out duration-300"
          >
            Edit product details
          </button>
          <button
            onClick={toggleEditSellingDetails}
            className="bg-dark-100 text-sm-regular font-medium rounded-[10px] px-4 py-2 hover:bg-gray-600 hover:text-white transition ease-in-out duration-300"
          >
            Edit selling details
          </button>
          <button
            onClick={toggleManageCustomProperties}
            className="bg-dark-100 text-sm-regular font-medium rounded-[10px] px-4 py-2 hover:bg-gray-600 hover:text-white transition ease-in-out duration-300 hidden"
          >
            Manage custom properties
          </button>
        </div>
      </div>
      <div className="default-page-height w-full mt-6">
        <div className="grid sm:grid-cols-12 grid-cols-1 gap-x-4">
          <div className="sm:col-span-8 col">
            <div className="w-full prod-details p-4">
              <div className="flex items-center gap-x-4">
                <img
                  src={
                    product?.default_properties?.image_url
                      ? product?.default_properties?.image_url
                      : QuicstockNoImage
                  }
                  alt={
                    product?.default_properties?.image_url
                      ? product?.default_properties?.name
                      : "QuicstockProductImageTwo"
                  }
                  className={"xl:h-[72px] h-[60px] rounded-full"}
                />
                <p className="font-medium xl:text-md-headline text-sm-headline">
                  {product?.default_properties?.name}
                </p>
              </div>
              <div className="w-full mt-6">
                <p className="font-medium xl:text-sm-headline text-sm-regular mb-6">
                  Product details
                </p>
                <ProductDetailsItem
                  label={"Quantity"}
                  value={`${formatCurrency((product?.total_quantity ?? 0)?.toString())} Pieces`}
                />
                <ProductDetailsItem
                  label={"Color"}
                  value={product?.default_properties?.color ?? "-"}
                />
                <ProductDetailsItem
                  label={"Brand"}
                  value={product?.default_properties?.brand ?? "-"}
                />
                <ProductDetailsItem
                  label={"Cost price"}
                  value={`${NAIRA}${formatCurrency(product?.pricing?.retail_price?.toString())}`}
                />
                <ProductDetailsItem
                  label={"Category"}
                  value={product?.default_properties?.category ?? "-"}
                />
                <ProductDetailsItem
                  label={"Weight"}
                  value={`${product?.default_properties?.weight ?? "-"}g`}
                />
                <ProductDetailsItem
                  label={"Description"}
                  value={product?.default_properties?.description ?? "-"}
                />
                <ProductDetailsItem
                  label={"Unit of measurement"}
                  value={
                    product?.uom_details?.map((u) => u.name)?.join(", ") ?? "-"
                  }
                />
              </div>
              <div className="my-4 border-[0.5px] border-[#EFEFEF]"></div>
              <div className="w-full mt-6">
                <p className="font-medium xl:text-sm-headline text-sm-regular mb-6">
                  Custom properties
                </p>
                {product?.custom_properties &&
                product?.custom_properties?.length > 0 ? (
                  product?.custom_properties?.map((cp, index) => (
                    <React.Fragment key={index + "cp"}>
                      {cp?.is_global ? (
                        <ProductDetailsItem
                          label={cp?.property_name ?? "-"}
                          value={cp?.value ?? "-"}
                          badge={"Global"}
                        />
                      ) : (
                        <div className="p-3 my-4 border-[0.5px] border-[#EFEFEF] rounded-[10px]">
                          <ProductDetailsItem
                            label={cp?.property_name ?? "-"}
                            value={
                              cp?.value
                                ? `${formatCurrency(cp?.value?.toString())}`
                                : "-"
                            }
                          />
                          {cp?.po_numbers?.length ? (
                            <div className="flex flex-wrap gap-x-2">
                              {cp?.po_numbers.map((pn) => (
                                <ProductOrder key={pn} orderNo={pn} />
                              ))}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <p className="text-labels">No custom properties</p>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-4 col sm:mt-0 mt-4">
            <div className="w-full mb-5">
              <div className="p-4 border-[0.5px] border-[#EFEFEF] rounded-[10px]">
                <p className="font-medium xl:text-sm-headline text-sm-regular mb-5">
                  Pricing details
                </p>
                <ProductDetailsItem
                  label={"Average cost price"}
                  value={`${NAIRA}${formatCurrency(product?.pricing?.average_cost_price?.toString())}`}
                  className="!mb-4"
                />
                <ProductDetailsItem
                  label={"Retail price"}
                  value={`${NAIRA}${formatCurrency(product?.pricing?.retail_price?.toString())}`}
                  className="!mb-0"
                />
              </div>
            </div>
            <div className="w-full mb-4">
              <div className="p-4 border-[0.5px] border-[#EFEFEF] rounded-[10px]">
                <p className="font-medium xl:text-sm-headline text-sm-regular mb-5">
                  Location
                </p>
                {(product?.locations?.bulk &&
                  product?.locations?.bulk?.length > 0) ||
                (product?.locations?.sales &&
                  product?.locations?.sales?.length > 0) ||
                (product?.locations?.online &&
                  product?.locations?.online?.length > 0) ? (
                  <>
                    {product?.locations?.bulk &&
                      product?.locations?.bulk?.length > 0 && (
                        <ProductLocation
                          title={"Bulk"}
                          locations={product?.locations?.bulk}
                        />
                      )}
                    {product?.locations?.sales &&
                      product?.locations?.sales?.length > 0 && (
                        <ProductLocation
                          title={"Sales"}
                          locations={product?.locations?.sales}
                        />
                      )}
                    {product?.locations?.online &&
                      product?.locations?.online?.length > 0 && (
                        <ProductLocation
                          title={"Online Sales"}
                          locations={product?.locations?.online}
                        />
                      )}
                  </>
                ) : (
                  <p className="text-labels">No location</p>
                )}
              </div>
            </div>
            <div className="w-full mb-4">
              <div className="p-4 border-[0.5px] border-[#EFEFEF] rounded-[10px]">
                <p className="font-medium xl:text-sm-headline text-sm-regular mb-5">
                  Purchase orders
                </p>
                {product?.purchase_orders &&
                product?.purchase_orders?.length > 0 ? (
                  product?.purchase_orders?.map((po) => (
                    <ProductPurchaseOrder key={po?.po_number} po={po} />
                  ))
                ) : (
                  <p className="text-labels">No purchase odrers</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit selling details */}
      <AppDrawer
        open={openEditSellingDetails}
        toggle={toggleEditSellingDetails}
        title={product?.name ?? ""}
        subtitle={"Change cost price"}
      >
        <EditSellingDetails
          cancel={toggleEditSellingDetails}
          save={handleSaveSellingDetails}
        />
      </AppDrawer>
      {/* Edit selling details */}

      {/* Manage custum properties */}
      <AppDrawer
        open={openManageCustomProperties}
        toggle={toggleManageCustomProperties}
        title={"Custom properties"}
        subtitle={"Change cost price"}
      >
        <ManageCustomProperties
          cancel={toggleManageCustomProperties}
          addNewProperty={handleAddNewProperty}
          save={handleSaveProperties}
        />
      </AppDrawer>
      {/* Manage custum properties */}

      {/* Add custom properties */}
      <AppDrawer
        open={openAddCustomProperties}
        toggle={toggleAddCustomProperties}
        title={"Custom properties"}
        subtitle={"Change cost price"}
      >
        <AddCustomProperties
          goBack={handleGoBackToManageCustomProperties}
          save={handleSaveCustomProperties}
        />
      </AppDrawer>
      {/* Add custom properties */}

      {/* Edit product details */}
      <AppDrawer
        open={openEditProductDetailsDrawer}
        toggle={() => {
          setActiveStep(0);
          toggleEditProductDetailsDrawer();
        }}
        title={"Product details"}
        subtitle={"Edit your product details and unit of measurement"}
      >
        <EditProduct
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={handleComplete}
          cancel={toggleEditProductDetailsDrawer}
          activeStep={activeStep}
          product={product}
          productDetails={productDetails}
        />
      </AppDrawer>
      {/* Edit product details */}
    </div>
  );
};

export default ProductDetails;
