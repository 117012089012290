import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import "../styles.scss";

const CreateAccount = () => {
  return (
    <div className="w-full h-full pt-10">
      <Outlet />
      <div
        className={
          "mx-auto bottom-20 flex items-center gap-x-2 justify-center mt-6"
        }
      >
        <p className="text-sm-regular font-medium text-gray-300">
          I already have an account,
        </p>
        <NavLink to={"/auth/login"}>
          <span className="text-sm-regular font-semibold text-primary-500">
            Login
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default CreateAccount;
