/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { AuthHeading } from "../components/AuthHeading";
import { Formik } from "formik";
import * as yup from "yup";
import PasswordField from "../../../components/Input/PasswordField";
import Button from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonState } from "../../../components/enum";
import { CreatePasswordModel } from "../../../model/auth.model";
import FieldName from "../../../components/FieldName";
import Back from "../../../components/Back";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { SetPassword } from "../../../redux/actions/auths.action";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import { ApiResponseModel } from "../../../model/base.model";
import { RootState } from "../../../redux/reducers";

const ACTIVE =
  "px-4 py-2 bg-link-green text-sm-regular rounded-[40px] text-white";
const INACTIVE =
  "px-4 py-2 bg-instock-bg text-sm-regular rounded-[40px] text-gray-300";

const CreatePassword = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.auths.isAuthActionLoading
  );
  const [values] = useState<CreatePasswordModel>({
    password: "",
    confirmPassword: "",
  });
  const [passwordCriteria, setPasswordCriteria] = useState({
    hasMinLength: false,
    hasSpecialChar: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
  });

  const validatePasswordCriteria = (password: string) => {
    return {
      hasMinLength: password.length > 7,
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumber: /[0-9]/.test(password),
    };
  };

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPasswordCriteria(validatePasswordCriteria(newPassword));
  };

  useEffect(() => {
    const token = localStorage.getItem("quicstock_app_token");
    if (!params?.token || !token) {
      navigate(`/auth`);
    }
  }, [params]);

  const createPassword = (values: CreatePasswordModel) => {
    dispatch(SetPassword(values))
      .then(async (res: ApiResponseModel) => {
        console.log(res);
        openNotification(
          <NotificationMessage
            type="success"
            title="Successful"
            message={res?.message}
          />,
          true
        );
        localStorage.removeItem("quicstock_app_token");
        navigate(`/auth`);
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="w-full">
      <div className="absolute top-[5%] right-[80px]">
        <Back isPrevious={false} />
      </div>
      <div className="text-left gap-y-6 flex flex-col">
        <AuthHeading subHeading={"Create Password"}>
          <p className="text-center mb-6 text-gray-300 text-sm-regular">
            Create a password to sign in to Quicstock
          </p>
        </AuthHeading>
        <Formik
          initialValues={values}
          onSubmit={createPassword}
          enableReinitialize={true}
          validationSchema={validator}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <Form onFinish={handleSubmit} form={form}>
              <div className={"mb-4"}>
                <FieldName name={"Password"} required />
                <PasswordField
                  showPassword={showPassword}
                  handleShowPassword={handleShowPassword}
                >
                  <Form.Item
                    name={"password"}
                    help={errors.password ? errors.password : ""}
                    validateStatus={errors.password ? "error" : "success"}
                  >
                    <Input
                      type={showPassword ? "text" : "password"}
                      className={"form-field_input_2"}
                      value={values?.password || ""}
                      onChange={(e) => {
                        handleChange(e);
                        handlePasswordChange(e);
                      }}
                      onBlur={handleBlur}
                      placeholder="Enter password"
                    />
                  </Form.Item>
                </PasswordField>
              </div>
              <div className={"mb-10"}>
                <FieldName name={"Confirm password"} required />
                <PasswordField
                  showPassword={showConfirmPassword}
                  handleShowPassword={handleShowConfirmPassword}
                >
                  <Form.Item
                    name={"confirmPassword"}
                    help={errors.confirmPassword ? errors.confirmPassword : ""}
                    validateStatus={
                      errors.confirmPassword ? "error" : "success"
                    }
                  >
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      className={"form-field_input_2"}
                      value={values?.confirmPassword || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter password again"
                    />
                  </Form.Item>
                </PasswordField>
              </div>
              <div className={"mb-10 flex flex-wrap gap-5"}>
                <div
                  className={passwordCriteria.hasMinLength ? ACTIVE : INACTIVE}
                >
                  {"> 7 Characters"}
                </div>
                <div
                  className={
                    passwordCriteria.hasSpecialChar ? ACTIVE : INACTIVE
                  }
                >
                  {"Special character"}
                </div>
                <div
                  className={passwordCriteria.hasUppercase ? ACTIVE : INACTIVE}
                >
                  {"Uppercase"}
                </div>
                <div
                  className={passwordCriteria.hasLowercase ? ACTIVE : INACTIVE}
                >
                  {"Lowercase"}
                </div>
                <div className={passwordCriteria.hasNumber ? ACTIVE : INACTIVE}>
                  {"Number"}
                </div>
              </div>
              <div className={"flex justify-center"}>
                <Button
                  css={"w-full"}
                  state={ButtonState.SECONDARY}
                  text={"Get into Quicstock"}
                  type={"submit"}
                  isLoading={isLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const validator = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

export default CreatePassword;
