/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { AuthHeading } from "../components/AuthHeading";
import { useNavigate } from "react-router-dom";
import AuthStepper from "../../../components/AuthStepper";
import Back from "../../../components/Back";
import "../styles.scss";
import { NAIRA } from "../../../utils/constants";
import Button from "../../../components/Button";
import { ButtonState } from "../../../components/enum";

const AccountPlan = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState<number>(-1);

  const handleSelected = (i: number) => {
    setIndex(i);
  };

  const handleClick = () => {
    navigate("/auth/sign-up/set-password");
  };

  return (
    <div className="w-full">
      <div className="mb-10 flex items-start justify-end">
        <Back isPrevious={true} />
      </div>
      <div className="text-left gap-y-2 flex flex-col">
        <AuthHeading subHeading={"Create your account"}>
          <p className="text-center mb-6 text-gray-300 text-sm-regular">
            Fil the details below to create a new account with Quicstock and
            let’s get the party started
          </p>
        </AuthHeading>
        <AuthStepper active={1} size={3}></AuthStepper>
        <section className="mt-6">
          <p className="text-sm-headline font-medium text-black mb-4">
            Pick a plan
          </p>

          <div
            className={`${index === 0 ? "active-gradient-border" : "gradient-border"} mb-6`}
            onClick={() => handleSelected(0)}
          >
            <div className="w-full flex justify-between items-center">
              <p className="text-black text-sm-headline">Basic plan</p>
              <p className="font-semibold text-black text-sm-headline">Free</p>
            </div>
            <p className="mt-4 text-sm-regular text-gray-300 leading-6">
              Korem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
          </div>

          <div
            className={`${index === 1 ? "active-gradient-border" : "gradient-border"} mb-6`}
            onClick={() => handleSelected(1)}
          >
            <div className="w-full flex justify-between items-center">
              <p className="text-black text-sm-headline">Standard plan</p>
              <p className="font-semibold text-black text-sm-headline">
                {NAIRA}25,000
              </p>
            </div>
            <p className="mt-4 text-sm-regular text-gray-300 leading-6">
              Korem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
          </div>

          <div
            className={`${index === 2 ? "active-gradient-border" : "gradient-border"} mb-6`}
            onClick={() => handleSelected(2)}
          >
            <div className="w-full flex justify-between items-center">
              <p className="text-black text-sm-headline">Premium plan</p>
              <p className="font-semibold text-black text-sm-headline">
                {NAIRA}45,000
              </p>
            </div>
            <p className="mt-4 text-sm-regular text-gray-300 leading-6">
              Korem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
          </div>
        </section>

        <div className={"flex justify-center"}>
          <Button
            css={"w-full"}
            state={ButtonState.SECONDARY}
            text={"Create a plan"}
            type={"submit"}
            disabled={index < 0}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountPlan;
