import React, { FC, useEffect, useState } from "react";
import "../styles.scss";
import { IAuthStepper } from "../model";

const AuthStepper: FC<IAuthStepper> = ({ active, size }) => {
  const [sizes, setSizes] = useState<string[]>([]);

  useEffect(() => {
    setSizes(Array(size).fill(""));
  }, [size]);

  return (
    <div
      className={`w-full justify-center items-center grid grid-cols-${size} gap-x-4`}
    >
      {sizes.map((s, index) => (
        <div
          key={index}
          className={`h-[4px] ${index <= active ? "bg-secondary-500" : "bg-dark-900"} rounded-[4px]`}
        ></div>
      ))}
    </div>
  );
};

export default AuthStepper;
